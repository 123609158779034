<script setup lang="ts">
import { NumberToString, USDollar, type ReconciliationField } from '@audit'
interface Props {
  value: ReconciliationField
  format?: 'currency' | 'number' | 'date' | 'string'
  fieldName: string
}

defineProps<Props>()

const dayjs = useDayjs()

const formatValue = (value: any, type: Props['format']) => {
  if (value === null || value === undefined) return undefined
  switch (type) {
    case 'currency':
      return USDollar.format(value)
    case 'number':
      return NumberToString.format(value)
    case 'date':
      return dayjs(value).format('DD/MM/YYYY')
    default:
      return value
  }
}
</script>

<template>
  <div class="flex flex-col gap-1 px-2 py-1 text-xs">
    <template
      v-if="
        value?.matchResult === 'valuesMatch' ||
        (!value.accuraitValue && !value.extractedValue)
      "
    >
      <div
        v-if="fieldName.toLowerCase() === 'tenant'"
        class="flex items-center gap-1"
      >
        {{ value.accuraitValue || value.extractedValue }}
      </div>
      <template v-else> - </template>
    </template>
    <template v-else>
      <div
        v-if="value.accuraitValue"
        class="bg-primary flex items-center gap-1 rounded-md bg-opacity-25 px-2 py-1 text-white"
      >
        {{ formatValue(value.accuraitValue, format) }}
      </div>
      <div
        v-if="value.extractedValue"
        class="flex items-center gap-1 rounded-md bg-blue-500 bg-opacity-25 px-2 py-1 text-white"
      >
        {{ formatValue(value.extractedValue, format) }}
      </div>
    </template>
  </div>
</template>
